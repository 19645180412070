.tns-outer {
    position: relative;
    .tns-controls {
        position: absolute;
        top: 50%;
        height: 50px;
        margin-top: -25px;
        left: -15px;
        right: -15px;
        display: flex;
        justify-content: space-between;
        align-items: center;
        &:focus {
            outline: none;
        }
        button {
            font-size: 20px;
            z-index: 5;
            opacity: .5;
            transition: all 0.3s;
            &:hover {
                opacity: 1;
            }
        }
    }
}
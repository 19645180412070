.home {
  .main-hero {
    h2 {
      margin-bottom: 16px;
      font-size: 42px;
      line-height: 36px;
      text-transform: none;
      @media (min-width: $s) {
        font-size: 70px;
        line-height: 52px;
      }
    }
    .hero {
      &__container {
        &--left {
          @media (min-width: 768px) and (max-width: 1023px) {
            &.md\:w-2\/5 {
              width: 50%;
            }
          }
        }
        &--right {
          @media (min-width: 768px) and (max-width: 1023px) {
            &.md\:w-3\/5 {
              width: 50%;
            }
          }
          .component__image {
            img {
              max-height: 500px;
            }
          }
        }
      }
    }
  }
  .secondary-hero {
    .hero {
      h2 {
        margin-bottom: 64px;
        margin: 32px 0;
        margin-bottom: 16px;
        font-size: 18px;
        line-height: 27px;
        text-transform: none;
        @extend %secondary-font;
        @media (min-width: $m) {
          margin: 16px 0 64px;
        }
      }
      &__container {
        align-items: center;
        &--left {
          order: 2;
          @media (min-width: 768px) {
            &.md\:w-2\/5 {
              width: 50%;
            }
          }
        }
        &--right {
          order: 1;
          @media (min-width: 768px) {
            &.md\:w-3\/5 {
              width: 50%;
            }
          }
        }
      }
      .component__link {
        a {
          margin-top: 0;
        }
      }
    }
  }

  .home-carousel {
    @media (min-width: 900px) and (max-width: 1280px) {
      max-width: 95%;
    }
  }

  &-blockquote {
    background: $blockquotes-bg;
    background-repeat: no-repeat;
    background-size: cover;
    .site-carousel {
      align-items: center;
    }
    .tns-outer {
      @extend .container-default;
      .tns-controls {
        @media (min-width: 1024px) {
          padding: 0 2rem;
        }
      }
      blockquote {
        color: $white;
      }
    }
  }
}

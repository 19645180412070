$white: #ffffff;
$black: #000000;
$hc-blue: #0071ce;
// greys TODO would be good if we could minimize the greys, some colors seem very similar
// names generated here: http://www.color-blindness.com/color-name-hue/
$white-smoke: #efefef;
$light-grey: #d3d3d3;
$suva-grey: #8c8c8c;
$shuttle-grey: #63666a;
$charcoal: #424242;
$nero: #141414;
// background colors
$white-background: $white;
$subtle-white-background: $white-smoke;
$light-grey-background: $suva-grey;
$grey-background: $shuttle-grey;
$dark-grey-background: $charcoal;
$subtle-black-background: $nero;
$black-background: $black;
// text color
$white-text: $white;
$subtle-white-text: $white-smoke;
$light-grey-text: $suva-grey;
$grey-text: $shuttle-grey;
$dark-grey-text: $charcoal;
$subtle-black-text: $nero;
$black-text: $black;
// border grey shades (max 5-6)
$white-border: $white;
$subtle-white-border: $white-smoke;

$placeholderColor: rgba($black, 0.7);
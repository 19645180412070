.pagination-wrapper {
    padding: 16px 0 8px;
    p {
        margin-bottom: 0;
    }
    .pagination {
        @media (max-width: $s) {
            padding: 8px 0 !important;
        }
        li {
            a {
                background-color: transparent;
                border-radius: 5px;
                padding: 5px 8px 1px 8px;
                color: $orange;

                &.is-active,
                &:hover {
                    background-color: $orange;
                    color: white;
                }
            }
        }
        &__results-qty {
            text-transform: uppercase;
            color: $orange;
        }
    }
}

@font-face {
    font-family: "ssg-templates-icons";
    src: url("../fonts/ssg-templates-icons.eot?9338732");
    src: url("../fonts/ssg-templates-icons.eot?9338732#iefix")
        format("embedded-opentype"),
      url("../fonts/ssg-templates-icons.woff2?9338732") format("woff2"),
      url("../fonts/ssg-templates-icons.woff?9338732") format("woff"),
      url("../fonts/ssg-templates-icons.ttf?9338732") format("truetype"),
      url("../fonts/ssg-templates-icons.svg?9338732#ssg-templates-icons")
        format("svg");
    font-weight: normal;
    font-style: normal;
}

[class^="icon-"]:before,
[class*=" icon-"]:before {
  font-family: "ssg-templates-icons";
  font-style: normal;
  font-weight: normal;
  speak: never;
  display: inline-block;
  text-decoration: inherit;
  width: 1em;
  margin-right: 0.2em;
  text-align: center;
  font-variant: normal;
  text-transform: none;
  line-height: 1em;
  margin-left: 0.2em;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

// variables

$icon-play: '\e800';
$icon-pause: '\e801';
$icon-blogger: '\e802';
$icon-down-open: '\e803';
$icon-left-open: '\e804';
$icon-right-open: '\e805';
$icon-up-open: '\e806';
$icon-twitter: '\1d54f';
$icon-facebook: '\f09a';
$icon-instagram: '\f16d';
$icon-vimeo: '\f27d';
$icon-shop: '\e835'; 
$icon-search: '\e8';
$icon-cancel: '\e807';
$icon-share: '\e808';
$icon-gplus: '\f0d5';
$icon-youtube-play: '\f16a';
$icon-behance: '\f1b4';
$icon-pinterest: '\f231';
$icon-wikipedia-w: '\f266';
$icon-snapchat-ghost: '\f2ac'; 
$icon-circle: '\f111'; 
$icon-user: '\e809'; 
$icon-shopping-bag: '\f290'; 

// classes

.icon-play:before { content: $icon-play; }
.icon-pause:before { content: $icon-pause; }
.icon-blogger:before { content: $icon-blogger; }
.icon-down-open:before { content: $icon-down-open; }
.icon-left-open:before { content: $icon-left-open; }
.icon-right-open:before { content: $icon-right-open; }
.icon-up-open:before { content: $icon-up-open; }
.icon-twitter:before { content: $icon-twitter; }
.icon-facebook:before { content: $icon-facebook; }
.icon-instagram:before { content: $icon-instagram; }
.icon-vimeo:before { content: $icon-vimeo; } 
.icon-shop:before { content: $icon-shop; } 
.icon-search:before { content: $icon-search; } 
.icon-cancel:before { content: $icon-cancel; } 
.icon-share:before { content: $icon-share; }
.icon-gplus:before { content: $icon-gplus; }
.icon-youtube-play:before { content: $icon-youtube-play; }
.icon-behance:before { content: $icon-behance; }
.icon-pinterest:before { content: $icon-pinterest; }
.icon-wikipedia-w:before { content: $icon-wikipedia-w; }
.icon-snapchat-ghost:before { content: $icon-snapchat-ghost; }
.icon-circle:before { content: $icon-circle; }
.icon-user:before { content: $icon-user; }
.icon-shopping-bag:before { content: $icon-shopping-bag; }
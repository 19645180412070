.supafolio-loader-container {
    .supafolio-loader {
        border-top-color: $primary-color;
    }
}
.book-detail {
    @media (max-width: $s) {
        max-width: none !important;
    }
    .book-content {
        img {
            @media (max-width: $s) {
                max-width: 75%;
            }
        }
    }
    .button-asset {
        @extend %primary-font;
        text-transform: uppercase;
        font-weight: normal;
        display: flex;
        justify-content: center;
        margin: 10px 0 0 0;
        font-size: 24px;
        padding-top: 18px;
        &:hover {
            color: $pink;
            background: $white;
        }
        &.book_preview {
            background: transparent;
            padding: 0;
            margin: 10px auto;
            border: none;
            a {
                border: 3px solid $black;
                text-transform: uppercase;
                background: $pink;
                font-weight: 400;
                font-size: 20px;
                line-height: 26px;
                padding-top: 18px;
                padding-bottom: 15px;
                width: 100%;
                border-radius: 9999px;
                margin-top: 0;
                line-height: 18px;

                &:hover {
                    color: $pink;
                    background: $white;
                }
            }
        }
        &--audio {
            box-shadow: none;
            background: transparent;
            &::before {
                margin-right: 16px;
            }
        }
        @media (max-width: $s) {
            margin: 10px auto;
        }
        a {
            color: $white;
        }
    }
    .book-title {
        margin-bottom: 0;
    }
    .book-contributors {
        margin: 0 0 32px;
        font-size: 20px;
        line-height: 27px;
        @extend %secondary-font;
    }
    .flextabs-container {
        @media (max-width: $s) {
            width: auto;
            margin: 0 -24px 32px;
        }
    }
    .flextabs {
        background: $cream;
        &__tab button {
            text-transform: uppercase;
            font-size: 20px;
            line-height: 26px;
            padding-top: 17px;
        }
        &__content {
            text-align: left !important;
        }
        @media (min-width: $s) {
            &__content {
                border-top: 2px solid #f0c3d8;
                font-size: 20px;
                line-height: 27px;
                padding-top: 64px;
                &::before,
                &::after {
                    border-top: 2px solid #f0c3d8;
                    height: calc(100% + 2px);
                    margin-top: -1px;
                }
                p,
                li {
                    margin-bottom: 15px !important;
                    font-family: soleil, sans-serif;
                    font-weight: 400;
                }
                &.product-details {
                    li {
                        margin-bottom: 0 !important;
                    }
                }
            }
            &__toggle {
                &--active {
                    &--last {
                        border: 2px solid;
                        border-color: #f0c3d8 #f0c3d8 $white;
                        border-bottom: 0;
                        margin-bottom: -2px;
                    }
                }
            }
        }
        @media (max-width: 767px) {
            width: 100%;
            margin: 16px -16px 0;
            &::before,
            &::after {
                content: none;
            }
            &__tab {
                position: relative;
                padding: 16px 24px;
                background: $orange;
                color: $white;
                font-size: 20px;
                &:not(:last-of-type) {
                    margin-bottom: 8px;
                }
                button {
                    color: $white;
                }
            }
            &__toggle {
                &::after {
                    content: "+";
                    position: absolute;
                    right: 24px;
                    top: 50%;
                    transform: translateY(-50%);
                    font-size: 58px;
                }
                &--active {
                    &::after {
                        content: "-";
                        position: absolute;
                        right: 24px;
                        top: 50%;
                        transform: translateY(-50%);
                        font-size: 64px;
                    }
                }
            }
            &__content {
                background: $white;
                padding: 16px 24px;
                text-align: center;
                margin-top: -8px;
                p,
                li {
                    margin-bottom: 32px !important;
                }
                &.product-details {
                    text-align: start;
                    li {
                        margin-bottom: 0 !important;
                    }
                }
            }
        }
    }
    .buy-button-wrapper {
        background: $yellow;
        @media (max-width: $s) {
            max-width: none;
            margin: 32px auto 24px;
            padding: 32px;
        }
        .retailers-wrapper {
            border: 1px solid $dark-grey;

            .retailers {
                li {
                    a {
                        &:hover {
                            color: $orange;
                        }
                    }
                }
            }

            .other-retailers {
                &:before {
                    top: 12px;
                }
            }
        }
        .format {
            text-transform: capitalize;
            font-size: 26px;
            line-height: 26px;
            margin-bottom: 25px;
        }
        .price {
            font-size: 20px;
            line-height: 26px;
            color: $dark-grey;
        }
        .buy-button {
            background-color: $orange;
            color: $black;
            border: 3px solid $black;
            border-radius: 100px;
            text-transform: uppercase;
            font-size: 24px;
            padding: 20px 32px 18px;
            @media (max-width: 480px) {
                padding: 20px 10px 18px;
            }
            &:hover {
                background: $white;
                color: $orange;
            }
        }
    }
    .other-formats {
        text-transform: capitalize;
        padding-bottom: 1.5rem;
        p {
            font-size: 20px;
            line-height: 26px;
            color: $dark-grey;
            margin: 3px 0 10px;
            &::after {
                content: ":";
            }
        }
        li {
            margin-bottom: 0;
        }
        a {
            color: $orange;
            @extend %secondary-font;
            &:hover {
                color: $dark-grey;
            }
        }
    }
    .books-carousel {
        padding: 0 32px;
    }
}

.btn-round {
  border-radius: 50px;
}

.book-detail {
  .button-asset {
    border-radius: 9999px;
    width: 100%;
    text-align: center;
    text-transform: uppercase;
    margin-top: 15px;
    padding: 15px 30px;
    font-weight: normal;
    cursor: pointer;

    &--audio {
      display: flex;
      flex-flow: row nowrap;
      align-items: center;
      justify-content: center;
      padding:10px 30px;
      &:before {
        font-size: 26px;
        margin-right: 5px;
        border-radius: 50%;
        background: black;
        color: white;
        padding-left: 1px;
        width: 30px;
        height: 30px;
        display: flex;
        align-items: center;
        justify-content: center;
      }
      &.playing {
        &:before {
          content: "\23F8";
        }
      }
    }
  }
}

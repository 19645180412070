.activity-detail {
    .asset-detail {
        align-items: flex-start;
        @media (max-width: $s) {
            text-align: center;
            align-items: center;
        }
        &__image {
            img {
                max-width: 90%;
                margin: 0 auto;
                @media (max-width: $s) {
                    max-width: 75%;
                }
            }
        }
        &__info {
            justify-content: flex-start;
        }
        &__category {
            margin: 8px 0 15px;
            @extend %secondary-font;
            font-size: 20px;
            line-height: 27px;
            text-transform: capitalize;
            @media (max-width: $s) {
                text-align: center;
            }
        }
        &__title {
            text-transform: capitalize;
            margin-bottom: 8px;
            font-size: $h2-font-size;
            line-height: 49px;
            @extend %primary-font;
            color: $black;
            text-align: center;
            @media (min-width: $s) {
                text-align: left;
            }
        }
        &__description {
            font-size: 20px;
            line-height: 27px;
            margin-bottom: 64px;
            @extend %secondary-font;
            @media (max-width: $s) {
                text-align: center;
                padding: 0 16px;
                margin-bottom: 32px;
            }
        }
        &__action {
            @include siteButton($pink, $white);
            font-size: 24px;
            text-transform: uppercase;
            padding: 20px 32px 18px 32px;
            display: inline-block;
            &:hover {
                background: $white;
                color: $pink;
            }
        }
        &__carousel {
            @include full_width_colour($white);
            &:after {
                z-index: 1;
            }
        }
        &__related-title {
            text-transform: capitalize;
            padding: 24px 0 32px;
            font-size: 40px;
            line-height: 49px;
            color: $black;
            @media (max-width: $s) {
                margin: 0;
                padding: 24px 0;
            }
        }
        &__related-subtitle {
            display: none;
        }
        .related-book {
            align-items: flex-start;
            @media (max-width: $s) {
                flex-direction: column;
                justify-content: center;
                align-items: center;
            }
            h4 {
                @extend %primary-font;
                text-transform: none;
                font-size: 20px;
                line-height: 26px;
                margin-bottom: 0;
                @media (max-width: $s) {
                    flex-direction: column;
                    justify-content: center;
                }
            }
            &__price {
                display: none;
            }
            &__summary {
                font-size: 20px;
                line-height: 27px;
                @extend %secondary-font;
            }
            &__content {
                @media (max-width: $s) {
                    width: 100%;
                    padding: 16px;
                    text-align: center;
                    margin-top: 16px;
                }
            }
        }
    }
}

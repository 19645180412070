.header {
  &__logo {
    background: $header-background;
    background-repeat: repeat-x;
    max-width: none;
    padding-top: 0 !important;
    > a:first-child {
      display: block;
      max-width: 390px;
      padding: 15px 0;
    }
    a[href="https://www.harpercollins.com/childrens/"]
    {
      right: 7%;
      @media (max-width: $s) {
        right: 0;
      }
    }
    &__kids-image {
      width: 65px;
    }
  }
}

.tns-outer {
    .tns-controls {
        button {
            width: 18px;
            height: 34px;
            background-size: contain;
            text-indent: -9999px;
        }
        [data-controls="prev"] {
            background-image: url("../img/arrow-prev.png");
        }
        [data-controls="next"] {
            background-image: url("../img/arrow-next.png");
        }
    }
}
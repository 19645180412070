.sailthru-form {
    margin-bottom: 10px;
    width: 100%;
    fieldset {
        border: none;
        margin: 0;
        padding: 0;
        display: flex;
        flex-wrap: wrap;
        width: 100%;
        align-items: flex-end;
        justify-content: space-between;
    }
    label {
        text-transform: uppercase;
        text-align: center;
        margin-bottom: 10px;
        display: block;
        font-family: inherit;
        @include breakpoint(md) {
            text-align: left;
        }
    }
    input {
        background: white;
        color: $black;
    }
    .invalid {
        border: rem(2px) solid $red !important;
    }
    &__email {
        width: 100%;
        margin-bottom: 15px;
        @include breakpoint(md) {
            width: 33%;
            margin-bottom: 0;
        }
        input[type=email] {
            border: rem(2px) solid $inputBorder;
            padding: rem(10px);
            width: 100%;
            font-size: 15px;
            height: 44px;
        }
    }

    &__restricted-age {
        text-align: center;
        padding: 15px;
        font-weight: bold;
        p {
            margin: 0;
        }
    }

    &__dob {
        width: 100%;
        margin-bottom: 15px;
        @include breakpoint(md) {
            width: 35%;
            margin-bottom: 0;
            flex-grow: 1;
            padding: 0 10px;
        }
        ul {
            list-style: none;
            padding: 0;
            margin: 0;
            display: flex;
            align-items: center;
            justify-content: space-between;
            li {
                width: 32%;
                padding: 0;
                margin: 0;
            }
        }
    }
    &__legal {
        font-size: 12px;
        line-height: 16px;
        a {
            color: inherit;
            font-weight: bold;
        }
    }
    .select-style {
        @include styled-dropdown();
        border-color: $inputBorder;
        width: 100%;
        label {
          top: 40px; 
          left: 0;
          &.error {
            color: $red;
          }
        }
    }

    &__submit {
        width: 100%;
        @include breakpoint(md) {
            width: 20%;
            margin-bottom: 0;
        } 
        button {
            width: 100%;
            padding: 0;
            height: 44px;
        }
    }
}
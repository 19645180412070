.authors,
.illustrators {
    main {
        h1 {
            text-align: center;
        }
        p {
            margin-bottom: 32px;
        }
    }
    .author-copy {
        h2 {
            @extend %primary-font;
            color: $purple;
            font-size: 24px;
            @media (min-width: $s) {
                font-size: 32px;
            }
        }
    }
}

@function strip-unit($number) {
  @if type-of($number) == "number" and not unitless($number) {
    @return $number / ($number * 0 + 1);
  }
  @return $number;
}

@function rem($px, $base-font-size: $base-font-size) {
  @return ($px / $base-font-size) * 1rem;
}

@mixin flexbox($justify-content, $flex-flow) {
  display: flex;
  justify-content: $justify-content;
  flex-flow: $flex-flow;
}

@mixin sliderLinks($hoverColor) {
  .component__link {
    a {
      border: 2px solid transparent;
      border-radius: 5px;
      margin-top: -10px;
      padding: 5px 15px;
    }
  }
  &.is-active,
  :hover {
    .component__link {
      a {
        background-color: $white;
        border: 2px solid $hoverColor;
        color: $hoverColor;
      }
    }
  }
}

//background colours
@mixin full_width_colour($color) {
  position: relative;
  &:before,
  &:after {
    position: absolute;
    top: 0;
    bottom: 0;
    width: 100%;
    content: "";
    flex: 1;
    background: $color;
    height: 100%;
  }
  &:before {
    right: 100%;
  }
  &:after {
    left: 100%;
  }
}

@mixin siteButton($background, $text) {
  background-color: $background;
  color: $text;
  font-size: 16px;
  line-height: 18px;
  padding: 10px 30px;
  transition: all 0.3s;
  box-shadow: 0 4px 6px -1px rgba(0, 0, 0, 0.1),
    0 2px 4px -1px rgba(0, 0, 0, 0.08);
  display: inline-block;
  &:hover {
    background-color: $text;
    color: $background;
  }
}

@mixin border-opacity($color, $opacity: 0.3) {
  border-color: $color; /* The Fallback */
  border-color: rgba($color, $opacity);
}


@mixin styled-dropdown($height: 40px) { 
  border: rem(2px) solid $inputBorder;
  overflow: hidden;
  background: $white;
  border-radius: 0;
  width: 200px;
  position: relative;
  font-size: em(14px);
  display: block;
  label {
    display: none;
  }
  &:after {
    background: transparent;
    font-family: "ssg-templates-icons";
    content: $icon-down-open;
    color: $inputBorder;
    backface-visibility: visible;
    position: absolute;
    top: 45%;
    right: 0;
    font-size: rem(20px);
    transform: translateY(-50%);
    padding: 0 rem(8px);
    pointer-events: none;
  }

  select {
    height: $height;
    text-transform: none;
    color: $black;
    font-size: 16px;
    -webkit-appearance: none;
    -moz-appearance: none;
    width: 120%;
    border: none;
    text-indent: 5px;
    background: transparent;
  }
}
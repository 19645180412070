.site-carousel {
    margin-top: 30px;
    // main books collections carousel
    align-items: flex-start;
    .slider-nav__item {
        .component {
            &__link {
                margin-top: 3px;
                @media (min-width: $m) {
                    min-height: 50px;
                }
            }
        }
    }
}

//tns settings
.tns-outer {
    .tns-controls {
        @media (min-width: $m) {
            left: -30px;
            right: -30px;
        }
        [data-controls="next"],
        [data-controls="prev"] {
            width: 40px;
            height: 43px;
            opacity: 1;
        }
        [data-controls="prev"] {
            background: url(https://supadu-io.imgix.net/harpercollins-icanread/icanread-site/arrow-left.png?auto=format);
            background-repeat: no-repeat;
        }
        [data-controls="next"] {
            background: url(https://supadu-io.imgix.net/harpercollins-icanread/icanread-site/arrow-right.png?auto=format);
            background-repeat: no-repeat;
        }
    }
}

.slider-nav {
    // characters carousel colours
    &.characters-nav {
        .slider-nav__item {
            &:hover,
            &:active {
                .component__image {
                    a {
                        &:after {
                            background-color: $yellow;
                        }
                    }
                }
                .component__link {
                    .book-title {
                        border-color: $yellow !important;
                    }
                }
            }
            .component__link {
                a {
                    &:hover,
                    &:active {
                        border-color: $yellow;
                    }
                }
            }
            &.is-active {
                .component__link {
                    .book-title {
                        border-color: $yellow;
                    }
                }
                .component {
                    &__image {
                        a {
                            &:after {
                                background-color: $yellow;
                            }
                        }
                    }
                    &__link {
                        a {
                            border-color: $yellow;
                        }
                    }
                }
            }
        }
    }
    &.levels-nav {
        .slider-nav__item {
            &:hover,
            &:active {
                .component__image {
                    a {
                        &:after {
                            background-color: $red;
                        }
                    }
                }
                .component__link {
                    .book-title {
                        border-color: $red !important;
                    }
                }
            }
            .component__link {
                margin-top: 8px;
                a {
                    &:hover,
                    &:active {
                        border-color: $red;
                    }
                }
            }
            &.is-active {
                .component__link {
                    .book-title {
                        border-color: $red;
                    }
                }
                .component {
                    &__image {
                        a {
                            &:after {
                                background-color: $red;
                            }
                        }
                    }
                    &__link {
                        a {
                            border-color: $red;
                        }
                    }
                }
            }
        }
    }
    &__item {
        &:hover,
        &:active {
            .component__link {
                .book-title {
                    background-color: $white;
                    color: $black;
                }
            }
        }
        .component {
            &__image {
                position: relative;
                z-index: 1;
                a {
                    transition: $animation-time all;
                    position: relative;
                }
            }
            &__link {
                a {
                    @extend %primary-font;
                    font-size: 20px;
                    line-height: 32px;
                    border: 3px solid transparent;
                    border-radius: 10px;
                    padding: 8px 5px;
                    @media (min-width: $m) {
                        padding: 8px 24px;
                    }
                    transition: $animation-time all;
                    margin-top: -12px;
                    &:hover,
                    &:active {
                        color: $black;
                    }
                }
            }
        }
        &.is-active {
            .component__link {
                .book-title {
                    background-color: $white;
                    color: $black;
                }
            }
            .component {
                &__link {
                    a {
                        color: $black;
                    }
                }
            }
        }
        @media (min-width: $s) {
            &:first-of-type {
                .component {
                    &__image {
                        justify-content: flex-end;
                        a {
                            margin-right: 48px;
                        }
                    }
                    &__link {
                        justify-content: flex-end;
                    }
                }
            }
            &:last-of-type {
                .component {
                    &__image {
                        justify-content: flex-start;
                        a {
                            margin-left: 48px;
                        }
                    }
                    &__link {
                        justify-content: flex-start;
                    }
                }
            }
        }
    }
}

.footer {
    padding: rem(30px) 0;;
    width: 100%;
    text-align: center;
    color: $black;
    font-size: rem(12px);
    background: $white;
    &__nav {
        ul.menu {
            list-style: none;
            @include flexbox(center, row wrap);
            li {
                @include flexbox(center, row wrap);
                &:not(:last-child) {
                    &:after {
                        content: "|";
                        display: inline-block;
                        padding: 0 rem(5px);
                    }
                }
            }
        }
    }
}
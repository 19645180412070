@import "variables";
@import "typography";

.container-default {
    margin: 0 auto;
    width: 100%;
    @media (min-width: 640px) {
        max-width: 640px;
    }
    @media (min-width: 768px) {
        max-width: 768px;
    }
    @media (min-width: 1024px) {
        max-width: 1024px;
    }
    @media (min-width: 1280px) {
        max-width: 1280px;
    }
}

body {
    background: $background-body;
}

main {
    overflow: hidden;
}

.white-background {
    background-color: $white;
    @include full_width_colour($white);
}

.pink-background {
    background-color: $pink;
    @include full_width_colour($pink);
}

.video-background {
    background-color: $background-video;
    @include full_width_colour($background-video);
}

.red-background {
    background-color: $red;
    @include full_width_colour($red);
}

.yellow-background {
    background-color: $yellow;
    @include full_width_colour($yellow);
}

.cream-background {
    background-color: $cream;
    @include full_width_colour($cream);
}

.darker-cream-background {
    background-color: $darker-cream;
    @include full_width_colour($darker-cream);
}

.blue-background {
    background-color: $dark-blue;
    @include full_width_colour($dark-blue);
}

.purple-background {
    background-color: $purple;
    @include full_width_colour($purple);
}

.rose-background {
    background-color: $rose;
    @include full_width_colour($rose);
}

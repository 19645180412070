.books-carousel {
    .supafolio-loader-container {
        margin: 20px auto;
    }
    .books-container {
        .book-image {
            padding: 10px;
            img {
                margin: 0 auto;
                @extend %box-shadow;
            }
        }
    }
}
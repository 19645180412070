.header__nav-draw {
    background-color: #1c1d1d;
    color: $white; 
    overflow: hidden;
    position: fixed;
    top: 0;
    bottom: 0;
    z-index: 30;
    width: 0;
    right: -300px;
    width: 300px;
    transition: transform .25s cubic-bezier(.165, .84, .44, 1); 
    &.is-active {
        transform: translateX(-300px);
        -webkit-transform: translateX(-300px);
        transition-duration: .45s;
    }

    &--header {
        text-align: right;
        padding: 12px;
        border-bottom: 1px solid #60948c;
        .icon-cancel {
            font-size: 16px;
        }
    }
    &--navigation {
        li {
            border-bottom: 1px solid #60948c;
        }
        a {
            color: $white;
            padding: 15px;
            display: block;
            &:hover {
                color: $white;
            }
        }
    }
} 

.header__overlay {
    display: block;
    position: fixed;
    height: 100%;
    width: 100%;
    top: 0;
    cursor: pointer;
    background-color: rgba(#000000, 0.5);
    opacity: 9;
    visibility: visible;
    transition: background-color var(--duration) var(--ease),
        opacity var(--duration) var(--ease),
        visibility var(--duration) var(--ease);
    @include breakpoint(md) {
        display: none;
    }
}
/*
	Breakpoints
*/
@mixin breakpoint($point) {
  @if $point == medium-handset {
    @media (min-width: rem(360px)) {
      @content;
    }
  }

  @if $point == large-handset {
    @media (min-width: rem(400px)) {
      @content;
    }
  }

  @if $point == small-handset-landscape {
    @media (min-width: rem(480px)) {
      @content;
    }
  }

  @if $point == medium-handset-landscape {
    @media (min-width: rem(600px)) {
      @content;
    }
  }

  @if $point == large-handset-landscape {
    @media (min-width: rem(720px)) {
      @content;
    }
  }

  @if $point == md {
    @media (min-width: rem(768px)) {
      @content;
    }
  }

  @if $point == large-tablet {
    @media (min-width: rem(840px)) {
      @content;
    }
  }

  @if $point == small-tablet-landscape {
    @media (min-width: rem(992px)) {
      @content;
    }
  }

  @if $point == large-tablet-landscape {
    @media (min-width: rem(1024px)) {
      @content;
    }
  }

  @if $point == large-desktop {
    @media (min-width: rem(1200px)) {
      @content;
    }
  }
}

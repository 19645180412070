@import url("https://use.typekit.net/lig0lld.css");
@font-face {
    font-family: "VAG Rounded Std";
    src: url("../fonts/VAG Rounded Std Bold.otf") format('opentype'), url("../fonts/VAG Rounded Std Bold.woff2") format("woff2"), url("../fonts/VAG Rounded Std Bold.woff") format("woff"), url("../fonts/VAG Rounded Std Bold.ttf") format("truetype");
}

%primary-font {
    font-family: "VAG Rounded Std";
    font-weight: 700;
    letter-spacing: 0.04em;
}

%secondary-font {
    font-family: soleil, sans-serif;
    font-weight: 400;
    font-style: normal;
}

.main-title,
.main-title * {
    @extend %secondary-font;
    font-size: 60px;
    line-height: 52px;
}

.subtitle,
.subtitle * {
    font-size: 20px;
    line-height: 32px;
    @extend %secondary-font;
    max-width: 730px;
    margin: 0 auto;
}

.primary-font,
.primary-font * {
    @extend %primary-font;
}

.primary-text-colour {
    color: $primary-color;
}

h1,
h2,
h3 {
    @extend %primary-font;
}

.secondary-text-colour {
    color: $secondary-color;
}

h4,
h5,
body,
.main-font {
    @extend %primary-font;
    color: $black;
}

body h1,
body h2 {
    font-size: 32px;
    line-height: 40px;
    margin-bottom: 8px;
    @media (min-width: $s) {
        margin-bottom: 16px;
        font-size: 40px;
        line-height: 48px;
    }
}

body {
    font-size: $base-font-size;
    line-height: 27px;
}

// text colour classes
.main-text-colour {
    color: $body-font-color;
}

.white-text-colour {
    color: $white;
}

.green-text-colour {
    color: $green;
}

.pink-text-colour {
    color: $pink;
}

.orange-text-colour {
    color: $orange;
}

.yellow-text-colour {
    color: $yellow;
}

.blue-text-colour {
    color: $blue;
}

.red-text-colour {
    color: $red;
}

.purple-text-colour {
    color: $purple;
}

.rose-text-colour {
    color: $rose;
}

h1,
h2 {
    font-size: $h1-font-size;
    line-height: 44px;
    margin-bottom: 8px;
    @media (min-width: $s) {
        margin-bottom: 16px;
    }
}

h3 {
    font-size: $h3-font-size;
    line-height: $h3-font-size;
    margin-bottom: 8px;
    margin-top: 16px;
}

h4 {
    font-size: $h4-font-size;
    line-height: $h4-font-size + 5;
    margin-bottom: 8px;
}

h5 {
    font-size: $h5-font-size;
    line-height: $h5-font-size + 5;
    margin-bottom: 8px;
}

blockquote {
    font-size: 30px;
    line-height: 48px;
    @media (max-width: 767px) {
        font-size: 18px;
        line-height: 27px;
    }
    cite {
        font-style: normal;
        text-transform: none;
        margin-top: 32px;
        display: block;
        font-size: 18px;
        line-height: 20px;
        @media (max-width: 767px) {
            font-size: 15px;
            line-height: 22px;
            margin-top: 16px;
        }
    }
}

.book-card ul li.book-title {
    line-height: 27px;
    font-size: 24px;
}

.character-tile {
    .component__link a {
        border-radius: 8px;
        border: 3px solid transparent;
        padding: 6px 5px;
        display: inline-block;
        @media (min-width: $m) {
            padding: 6px 30px;
        }
        &:hover {
            color: $black;
        }
    }
    &:hover {
        .component__link a {
            border-color: $yellow;
            background-color: $white;
        }
    }
}

.separator {
    padding-top: 32px;
    border-top: 3px solid $section-border-top;
}

.component__text a {
    text-decoration: underline;
    text-underline-offset: 2px;
    font-weight: 700;
    font-style: italic;
}

a {
    color: $body-font-color;
    &:hover {
        color: $secondary-color;
        cursor: pointer;
    }
}

p {
    color: $primary-color;
    margin-bottom: 8px;
}

.quiz-button {
    @extend %secondary-font;
    @include siteButton($secondary-color, $white);
}

.format-button.primary-button {
    border: 2px solid $primary-color;
    color: $primary-color;
    &.active {
        color: $white;
    }
    &:hover,
    &.active {
        background-color: $primary-color;
        &:after {
            border-top-color: $primary-color;
        }
    }
}

.primary-button {
    @extend %primary-font;
    @include siteButton($pink, $white);
    border: 3px solid $black;
    border-radius: 100px;
    color: $white;
    font-size: 20px;
    padding: 15px 40px 14px;
    &:hover {
        background-color: $white;
        color: $pink;
    }
}

.secondary-button {
    @extend %primary-font;
    @include siteButton($white, $black);
    border: 3px solid $black;
    border-radius: 100px;
    color: $black;
    line-height: 26px;
    padding: 11px 40px 10px;
    &:hover {
        background-color: $black;
        color: $white;
    }
}

.pink-button {
    @extend %primary-font;
    @include siteButton($pink, $white);
}

.white-button {
    @extend %secondary-font;
    @include siteButton($white, $secondary-color);
}

.button-asset--audio {
    @include siteButton($white, $black);
    &:hover {
        background-color: $white;
        color: $black;
    }
}

.back-button {
    margin: 20px 0 48px 35px;
    a {
        @extend %secondary-font;
        text-transform: capitalize;
        position: relative;
        font-size: 20px;
        color: $orange;

        &::before {
            content: "<";
            position: absolute;
            display: block;
            left: -25px;
            height: 23px;
            width: 23px;
            transform: translateY(-50%);
            top: 13px;
            @media (max-width: $s) {
                height: 18px;
                width: 18px;
                top: 10px;
            }
        }
    }
}

$white: white;
$black: #000;
$dark-grey: #454545;
$orange: #ef8432;
$purple: #7f458d;
$cream: #fffdf1;
$darker-cream: #fff3b1;
$yellow: #fade4b;
$red: #ed1c24;
$blue: #81dcf9;
$dark-blue: #0055a5;
$mid-blue: #6d8fbe;
$sky-blue: #bee5fb;
$rose: #fff5ff;
$pink: #db3659;
$light-pink: #f0c3d8;
$green: #3d9059;
$dark-purple: #682D76;

//activities
$activities-bg: #faebf2;

$max-width: 1060px;
$animation-time: 0.3s;
$section-border-top: $red;
$background-body: $white;
$body-font-color: $black;
$primary-color: $black;
$secondary-color: $pink;
$background-buy-section: $yellow;
$button-link-hover: #db3659;

$assetBackgrounds: #9f9ad7, $pink, $orange, $yellow, #82c877;

/* Header */
$header-background: url(https://supadu-io.imgix.net/harpercollins-icanread/icanread-site/background-header.png?auto=format);

$blockquotes-bg: url(https://supadu-io.imgix.net/harpercollins-icanread/icanread-site/bg-blockquote.png?auto=format);

/* Video */
$background-video: #dc6998;

/* NAVIGATION */
$nav-links-colour: $black;
$nav-links-colour-hover: $pink;
$nav-background-colour: $blue;

/* default font sizes */

$base-font-size: 20px;
$h1-font-size: 40px;
$h2-font-size: 40px;
$h3-font-size: 24px;
$h4-font-size: 18px;
$h5-font-size: 16px;

//breakpoints
$m: 1023px;
$s: 767px;

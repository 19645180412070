.embed-container {
    position: relative;
    padding-bottom: 56.25%;
    height: 0;
    overflow: hidden;
    max-width: 100%;
    iframe,
    object,
    embed {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
    }
}

.embed-video {
    display: flex;
    margin-bottom: rem(10px);
    max-height: 435px;
    &:after {
        content: "";
        display: table;
        clear: both;
    }
    &__iframe {
        overflow: hidden;
        position: relative;
        width: 100%;
        &:before {
            content: "";
            display: block;
            padding-bottom: 56.25%;
        }
        &:after {
            content: "";
            display: table;
            clear: both;
        }
        &--playlist {
            @include breakpoint(large-tablet) {
                width: 70%;
                border-right: 30px solid transparent;
                float: left;
            }
        }
    }
    &__playlist {
        float: right;
        width: 100%;
        display: none;
        overflow-y: scroll;
        @include breakpoint(large-tablet) {
            width: 30%;
            display: block;
            flex-direction: column;
            justify-content: space-between;
        }
        &__item {
            width: 100%;
            margin-bottom: 15px;
            cursor: pointer;
            display: flex;
            align-items: flex-start;
            transition: all $animation-time;
            &:hover,
            &.active {
                background-color: $white;
            }
            &:last-of-type {
                margin-bottom: 0;
            }
            &__thumb {
                width: 70px;
                width: 120px;
                flex-shrink: 0;
                background-position: center;
                background-size: cover;
                &:before {
                    content: "";
                    display: block;
                    padding-top: 56%;
                }
            }
            &__desc {
                padding: 5px;
                margin-left: 10px;
                h5 {
                    font-size: rem(12px);
                    margin-bottom: 0.1em;
                    max-height: 4.5em;
                    font-weight: normal;
                    margin-top: 0;
                }
                .item-creator {
                    display: none;
                }
                p {
                    margin-bottom: 0;
                }
            }
        }
    }
    iframe {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
    }
}
.blog-detail {
    &__info {
        padding: 32px 16px;
        @include breakpoint(md) {  
            padding: 64px 16px 32px;
        }
        @include breakpoint(large-tablet-landscape) {
            max-width: 55%;
            margin: 0 auto;
        }
    }
    &__categories {
        text-align: center;
        margin-bottom: 32px;
        .category-link {
            text-decoration: underline;
            text-transform: uppercase;
            font-size: 14px;
            &:not(:last-of-type) {
                margin-right: 16px;
            }
        }
    }
    &__title {
        text-align: center;
        margin-bottom: 24px;
    }
    &__featured-image {
        width: 100%;
        max-height: 500px;
        object-fit: cover;
    }
    &__content {
        padding: 32px 16px;
        ul {
            list-style-type: initial;
            padding-inline-start: 40px;
        }
        ol {
            list-style-type: decimal;
            padding-inline-start: 40px;
        }
        @include breakpoint(md) {
            padding: 32px 16px 64px;
        }
    }
    &__related-blogs-title {
        text-align: center;
        padding: 16px;
    }
    &__related-blogs {
        display: flex;
        flex-wrap: wrap;
        .blog-card {
            padding: 16px;
            flex: 0 0 100%;
            @include breakpoint(md) {
                flex: 0 0 50%;
                padding: 26px;
            }
            @include breakpoint(large-tablet-landscape) {
                flex: 0 0 33%;
            }
            &__image {

            }
            &__categories {
                justify-content: center;
                padding: 16px 0 8px;
                .category-link {
                    background-color: black;
                    color: $white;
                    padding: 8px 12px;
                    text-transform: uppercase;
                    border-radius: 4px;
                    margin-bottom: 8px;
                    font-size: 12px;
                    @include breakpoint(md) {
                        font-size: 14px;
                    }
                    &:not(:last-of-type) {
                        margin-right: 8px;
                    }
                }
            }
            &__title {

            }
        }
    }
    // If featured book carousel used in template
    .books-carousel {
        .book-card {
            &__image {
                min-height: auto;
            }
        }
    }
}
.hero-carousel-container {
  &.tns-outer {
    .tns-controls {
      left: 24px;
      right: 24px;
      [data-controls="prev"] {
        background-image: url("../img/arrow-white-prev.png");
      }
      [data-controls="next"] {
        background-image: url("../img/arrow-white-next.png");
      }
    }
    .hero-carousel {
      display: flex;
      justify-content: center;
      align-items: stretch;
      .tns-item {
        display: flex;
        align-items: stretch;
        .hero {
          width: 100%;
          display: flex;
          align-items: center;
          justify-content: center;
          flex-flow: column nowrap;
        }
      }
    }
  }
}
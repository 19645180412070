.search-bar {
    width: 270px;
    position: relative;
    @media (max-width: 767px) {
        width: 100%;
    }
    &::after {
        content: '';
        background-image: url(data:image/svg+xml;charset%3DUS-ASCII,%3Csvg%20xmlns%3D%22http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg%22%20width%3D%2264%22%20height%3D%2264%22%20viewBox%3D%220%200%2064%2064%22%3E%20%20%20%20%3Cpath%20fill%3D%22%231a1919%22%20d%3D%22M26%2C5%20C37.5982847%2C5%2047%2C14.4017153%2047%2C26%20C47%2C30.9246356%2045.3050076%2C35.4532617%2042.4665714%2C39.0343296%20C43.6482918%2C38.8803419%2044.8859887%2C39.2579752%2045.7941068%2C40.1660932%20L45.7941068%2C40.1660932%20L57.8338028%2C52.2057894%20C59.3886817%2C53.7593325%2059.3886817%2C56.2797318%2057.8341068%2C57.8343068%20C56.2795825%2C59.3888311%2053.7604175%2C59.3888311%2052.2058932%2C57.8343068%20L52.2058932%2C57.8343068%20L40.1658932%2C45.7943068%20C39.2577752%2C44.8861887%2038.8801419%2C43.6484918%2039.0330541%2C42.4664742%20C35.4532617%2C45.3050076%2030.9246356%2C47%2026%2C47%20C14.4017153%2C47%205%2C37.5982847%205%2C26%20C5%2C14.4017153%2014.4017153%2C5%2026%2C5%20Z%20M44.3798932%2C41.5803068%20C43.6064682%2C40.8068817%2042.3528675%2C40.8068817%2041.5804106%2C41.5800028%20L41.5804106%2C41.5800028%20L41.4646727%2C41.705919%20C40.8088242%2C42.4836953%2040.8473883%2C43.6473747%2041.5801068%2C44.3800932%20L41.5801068%2C44.3800932%20L53.6201068%2C56.4200932%20C54.3935825%2C57.1935689%2055.6464175%2C57.1935689%2056.4198932%2C56.4200932%20C57.1933183%2C55.6466682%2057.1933183%2C54.3930675%2056.4198932%2C53.6203068%20L56.4198932%2C53.6203068%20Z%20M26%2C7%20C15.5062847%2C7%207%2C15.5062847%207%2C26%20C7%2C36.4937153%2015.5062847%2C45%2026%2C45%20C36.4937153%2C45%2045%2C36.4937153%2045%2C26%20C45%2C15.5062847%2036.4937153%2C7%2026%2C7%20Z%22%3E%3C%2Fpath%3E%3C%2Fsvg%3E);
        background-repeat: no-repeat;
        background-size: contain;
        display: inline-block;
        height: 1.5rem;
        width: 1.5rem;
        position: absolute;
        right: 8px;
        top: 50%;
        transform: translateY(-50%);
    }
    &__container {
        position: absolute;
        padding: 64px;
        background: white;
        display: none;
        left: 0;
        right: 0;
    }
    &__input {
        position: relative;
        color: black;
        width: 100%;
        border-radius: 2px;
        border: 1px solid grey;
        padding: 8px;
        font-size: 14px;
        
    }
    &__results {
        position: absolute;
        top: 41px;
        right: 0;
        background: white;
        color: black;
        width: 360px;
        box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;
        z-index: 1;
        @media (max-width: 767px) {
            width: 100%;
            left: 0;
        }
    }
    &__result-card {
        padding: 8px 16px 16px;
        &:not(:first-of-type) {
            border-top: 1px solid #dadce0;
        }
        a {
            width: 100%;
            display: flex;
        }
        &__image {
            width: 64px;
            margin-right: 16px;
        }
        &__content {
            display: flex;
            flex-direction: column;
            color: #1a1919;
            align-items: flex-start;
            text-align: start;
            .title {
                font-size: 12px;
                margin-bottom: 8px;
            }
            .price {
                font-size: 14px;
                font-weight: bold;
            }
        }
    }
    &__view-more {
        padding: 16px;
        background: #f2f2f2;
        a {
            color: #1a1919 !important;
            font-size: 14px !important;
        }
    }
    &__no-results {
        padding: 16px;
        color: #1a1919;
        text-transform: none;
        font-style: italic;
        font-size: 14px;
    }
}
.book-detail {
  .b2lbiblet-holder {
    width: 100%;
    .b2lButtonEmbed {
      margin: 15px auto;
      border: none;
      font-weight: inherit;
      font-style: inherit;
      background-color: transparent;
      font-family: inherit;
      font-size: inherit;
    }
  }
  .buy-button-wrapper {
    .format {
      font-size: 30px;
      line-height: normal;
    }
    .price {
      font-size: 24px;
    }
  }

  .awards,
  .reviews {
    ul {
      li {
        margin-bottom: rem(5px);
      }
    }
  }

  .retailers-wrapper {
    width: 100%;
    margin-top: 30px;
    * {
      width: 100%;
    }
    .other-retailers {
      background-color: $white;
      padding: 5px 10px;
      font-size: rem(18px);
      text-align: left;
      position: relative;

      &:before {
        position: absolute;
        right: 5px;
        transform: rotate(90deg);
      }
    }

    .retailers {
      text-align: left;
      background: $white;
      max-height: 0;
      overflow: hidden;
      transition: all 0.3s;

      a {
        padding: 5px 10px;
        display: block;
        font-size: 18px;
      }

      li {
        &:last-child {
          padding-bottom: 5px;
        }
      }

      &.open {
        max-height: 10000px;
        overflow: visible;
      }
    }
  }
  .product-tabs {
    background-color: $white;
    @include full_width_colour($white);
    @include breakpoint(md) {
      width: 100%;
      background-color: transparent;
      &:before,
      &:after {
        display: none;
      }
    }
    .author-tab {
      p {
        display: inline-block;
      }
    }
  }
  .related-books-title {
    text-transform: capitalize;
  }
}

.form {
    ::-webkit-input-placeholder {
        color: $placeholderColor;
    }
    :-ms-input-placeholder { 
        color: $placeholderColor;
    }
    ::placeholder {
        color: $placeholderColor;
    }
    .form-text {
        input {
            padding: 10px;
            color: $black;
        }
    }
    .form-button {
        padding: 10px;
        text-transform: uppercase;
        color: $black;
        &:hover {
            cursor: pointer;
        }
    }
}
.main-navigation {
    &.show-menu {
        .main-menu {
            display: block;
            @include breakpoint("large-handset-landscape") {
                display: flex;
            }
        }
    }
    &.sticky-nav {
        position: fixed;
        top: 0;
        left: 0;
        right: 0;
        z-index: 999;
        transition: 0.3s ease-in-out all;
    }
}
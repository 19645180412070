.newsletter {
    h2 {
        color: $black;
        text-align: center;
        padding-bottom: 16px;
        font-size: 28px;
        line-height: normal;
        text-transform: uppercase;
        @extend %primary-font;
        @media (min-width: $m) {
            text-align: left;
            padding: 0 32px 0 0;
            font-size: 28px;
            line-height: 35px;
        }
    }
    .sailthru-form {
        label {
            font-size: 14px;
            margin-bottom: 5px;
        }
        &__email {
            label {
                text-transform: capitalize;
                color: $black;
            }
        }
        &__dob {
            label {
                text-transform: capitalize;
                color: $black;
            }
            .select-style {
                border-radius: 8px;
            }
        }
        &__legal {
            color: $black;
            a {
                &:hover {
                    color: $sky-blue;
                }
            }
        }
        &__submit {
            button {
                background-color: $pink;
                color: $white;
                text-transform: uppercase;
                font-size: 20px;
                padding-top: 3px;
                font-size: 20px;
                &:hover {
                    background-color: $white;
                    color: $pink;
                }
            }
        }
        &__restricted-age {
            p {
                color: $white;
            }
        }
    }
}

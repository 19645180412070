.main-navigation {
    background-color: $nav-background-colour;
    border-top: 3px solid $black;
    border-bottom: 3px solid $black;
    padding: 10px;
    @media (min-width: 768px) {
        padding: 0 8px;
    }
    &.show-menu {
        padding-bottom: 32px;
        @media (min-width: 768px) {
            padding: 0;
        }
    }
    .main-menu {
        padding: 4px 0;
        @media (max-width: 767px) {
            margin-top: 8px;
        }
        li {
            margin: 0;
            a {
                height: 100%;
                display: inline-block;
                padding: 6px 16px;
                color: $nav-links-colour;
                @extend %primary-font;
                font-size: 20px;
                line-height: 24px;
                letter-spacing: 0.04em;
                text-transform: uppercase;
                margin: 6px 0;

                @media (min-width: $m) {
                    padding: 4px 20px;
                }
                &:hover {
                    color: $nav-links-colour-hover;
                }
                &.is-active {
                    color: $nav-links-colour-hover;
                }
            }
            .search-bar {
                padding-left: 30px;
                max-width: 260px;
                @media (max-width: $s) {
                    margin: 10px auto;
                    padding-left: 0;
                }
            }
        }
    }
    .hamburger-box {
        .hamburger-inner,
        ::after,
        ::before {
            background-color: $black !important;
        }
    }
}

$total-columns: 12;
$gutter: 3%;
$base-font-size: 16px;
$animation-time: 0.3s;
$black: black;
$white: white;
$max-width: 1200px;
$red: #FF0000;
.text-facebook {
    color: #335392;
}

$background-defer: #000;
%box-shadow {
    box-shadow: 0 4px 6px -1px rgba(0, 0, 0, .2), 0 2px 4px -1px rgba(0, 0, 0, .08);
}

$inputBorder: #111111;
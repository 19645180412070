.supafolio-loader-container {
    margin: 200px auto;
    .supafolio-loader {
        border: 5px solid rgba($black, 0.1);
        border-top: 5px solid $black;
        border-radius: 50%;
        width: 100px;
        height: 100px;
        animation: spin 2s linear infinite;
        margin: 0 auto;
    }
}

@keyframes spin {
    0% {
        transform: rotate(0deg);
    }
    100% {
        transform: rotate(360deg);
    }
}

.blog-detail {
    background-color: $cream;
    .category-link {
        background-color: transparent !important;
        color: $orange !important;
        text-decoration: underline;
        font-size: 14px;
        line-height: 20px;
        text-transform: uppercase;
        padding: 0 !important;
        &:hover {
            color: $pink !important;
        }
    }
    &__content {
        @extend %secondary-font;
        letter-spacing: normal;
        @media(max-width: $s) {
            font-size: 18px;
            line-height: 25px;
        }
        * {
            margin-bottom: 16px;
        }
        h2 {
            font-size: 30px;
        }
        h3 {
            font-size: 24px;
        }
        a {
            text-decoration: underline;
            text-underline-offset: 2px;
            font-weight: 700;
            font-style: italic;
        }
        blockquote {
            background: #f9f9f9;
            border-left: 10px solid #ccc;
            margin: 32px 16px;
            padding: 32px 16px;
            text-align: start;
        }
        blockquote:before {
            color: #ccc;
            content: "\201C";
            font-size: 4em;
            line-height: 0.1em;
            margin-right: 0.25em;
            vertical-align: -0.4em;
        }
        blockquote p {
            display: inline;
        }
    }
    &__featured-blog-list {
        background-color: $cream;
        @include full_width_colour($cream);
        .main-blog__info {
            background-color: $cream !important;
        }
    }
}

.activities {
    background-color: $cream;
    .container {
        @media (max-width: 1279px) {
            max-width: none !important;
        }
    }
    &__title,
    &__subtitle {
        background-color: $cream;
        @include full_width_colour($cream);
        h1,
        p {
            margin-bottom: 0;
        }
    }
    &__title {
        padding-bottom: 16px;
    }
    .assets-filters {
        background-color: $cream;
        @include full_width_colour($cream);
        border-bottom: 8px solid $yellow;
        &:before,
        &:after {
            top: 8px;
            border-bottom: 8px solid $yellow;
        }
        margin-bottom: 0;
        padding-bottom: 64px;
        @media (max-width: $s) {
            padding-left: 0;
            padding-right: 0;
        }
        .tns-controls {
            left: -25px;
            right: -25px;
            @media (min-width: $s) {
                left: -15px;
                right: -15px;
            }
        }
        .list-group-item {
            text-align: center;
            padding: 10px;
            @media (min-width: $s) {
                padding: 16px;
            }
            @media (min-width: 900px) and (max-width: 1279px) {
                padding: 16px 8px;
            }
            @for $i from 1 through length($assetBackgrounds) {
                &:nth-child(#{length($assetBackgrounds)}n + #{$i}) {
                    a {
                        background-color: nth($assetBackgrounds, $i);
                        color: $white;
                        &:hover,
                        &.is-active {
                            &:after {
                                top: -10px;
                                left: -10px;
                                bottom: -10px;
                                right: -10px;
                                background-color: $white;
                                box-shadow: 0px 0px 6px rgba(0, 0, 0, 0.25);
                            }
                        }
                    }
                }
            }
            a {
                display: flex;
                align-items: center;
                justify-content: center;
                border-radius: 50%;
                width: 100%;
                margin: 0 auto;
                font-size: 15px;
                line-height: 18px;
                position: relative;
                @media (min-width: $s) {
                    font-size: 16px;
                    line-height: 20px;
                }
                @media (min-width: $m) {
                    font-size: 20px;
                    line-height: 25px;
                }
                &:after {
                    content: "";
                    position: absolute;
                    top: 0;
                    left: 0;
                    bottom: 0;
                    right: 0;
                    z-index: -1;
                    border-radius: 50%;
                    transition: all 0.3s;
                }
            }
        }
    }
    .pagination-top {
        margin: 32px 0 16px;
        .pagination {
            margin-left: auto;
            &__results-qty {
                display: none;
            }
        }
    }
    .pagination-bottom {
        margin-bottom: 0;
    }
    .pagination {
        li {
            margin-bottom: 16px;
        }
    }
    .assets-list {
        @media (min-width: 1024px) {
            grid-template-columns: repeat(5, 1fr);
        }
        .book-card {
            .book-title {
                padding: 8px;
                a {
                    text-transform: none;
                    line-height: 24px;
                    @extend %secondary-font;
                    font-size: 18px;
                }
            }
        }
    }
}

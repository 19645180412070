.books {
    .characters-carousel {
        border-bottom: 8px solid $yellow;
        @media (min-width: 768px) {
            max-width: 100%;
        }
        @media (max-width: 1024px) {
            padding-left: 30px;
            padding-right: 30px;
        }
        @media (min-width: 1024px) {
            max-width: 1024px;
        }
        @media (min-width: 1280px) {
            max-width: 1280px;
        }
        &:before,
        &:after {
            border-bottom: 8px solid $yellow;
            top: 8px;
        }
        .character-tile {
            .component__link a {
                padding: 8px 12px;
                font-size: 16px;
                line-height: normal;
                margin-top: -4px;
            }
        }
    }
    .search-sdk {
        padding-top: 32px;
    }
    .info {
        padding: 24px 0;
        @media (min-width: $s) {
            padding: 32px 0;
        }
        &__meta {
            margin-bottom: 16px;
            h2 {
                @extend %primary-font;
            }
        }
        *,
        &__pagination {
            @extend %secondary-font;
        }
        &__pagination {
            .list-inline {
                .page {
                    &.disabled,
                    &:hover {
                        background-color: $orange;
                    }
                }
                .page-prev,
                .page-next {
                    &:hover {
                        color: $white;
                    }
                }
            }
        }
    }
    .results-container {
        &__filters {
            @media (min-width: $s) {
                flex: 0 0 35%;
                a {
                    font-size: 18px;
                }
            }
            @media (min-width: $m) {
                min-width: 275px;
                flex: 0 0 25%;
            }
        }
        &__results {
            a {
                font-size: 18px;
            }
            @media (min-width: $s) {
                flex: 0 0 65%;
            }
            @media (min-width: $m) {
                flex: 0 0 70%;
            }
        }
        &__results-item {
            &:hover {
                a {
                    color: $pink;
                }
            }
        }
        * {
            @extend %secondary-font;
        }
        &__filters {
            * {
                color: $purple;
            }
            a {
                padding-bottom: 16px;
                @media (min-width: $s) {
                    padding-bottom: 10px;
                }
                display: block;
            }
        }
        &__filter-option {
            padding: 0;
        }
        &__filter-option-title {
            font-weight: bold;
        }

        &__filter-option-link {
            color: $white;
            &.disabled {
                text-decoration: underline;
                color: $white
            }
            &:hover {
                color: $pink;
            }
            @media (min-width: $s) {
                color: $purple;
                &.disabled {
                    color: $black;
                    text-decoration: none;
                }
            }
        }
        &__toggle-filters-button {
            font-family: "VAG Rounded Std" !important;
            font-weight: 700 !important;
            letter-spacing: 0.04em;
            background-color: $purple;
            color: $white;
            text-transform: lowercase;
            &::first-letter {
                text-transform: capitalize;
            }
        }
        &__filter-dropdown-button{
            background-color: $purple;
            * {
                color: $white;
            }
            &.active {
                background: $dark-purple;
            }
        }
        &__filter-group {
            background-color: $dark-purple;
            @media (min-width: $s) {
                background-color: transparent;
            }
        }
    }
}

.skip-link {
    padding: 5px 10px;
    position: absolute;
    top: -45px;
    left: -45px;
    color: white;
    border: 1px solid white;
    background: transparent;
    transition: top $animation-time ease-out, background $animation-time linear;
    z-index: 100;
    font-size: 14px;
    line-height: 14px;
    &:focus {
        left: 5px;
        top: 5px;
        background: $hc-blue;
        transition: top $animation-time ease-in, background $animation-time linear;
    }
}
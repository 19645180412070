// custom normalize css. default normalise.css being included in via node_modules
html {
    background: white;
}

body {
    overflow-x: hidden;
}

img {
    max-width: 100%;
    height: auto;
}

main {
    overflow: hidden;
}

section,
.section {
    padding-top: 0;
    padding-bottom: 0;
}

.sticky-header--container  {
    background-color: $white;
    &.is-fixed {
        position: fixed;
        left: 0;
        right: 0;
        top: 0;
        transition: none;
        z-index: 20;
        -webkit-transform: translate3d(0,-100%,0);
        transform: translate3d(0,-100%,0);
        border-bottom: 1px solid $black; 
    } 
    &.is-open {
        -webkit-transform: translate3d(0, 0, 0);
        transform: translate3d(0, 0, 0);
        transition: -webkit-transform 0.4s cubic-bezier(0.165, 0.84, 0.44, 1);
        transition: transform 0.4s cubic-bezier(0.165, 0.84, 0.44, 1);
        transition: transform 0.4s cubic-bezier(0.165, 0.84, 0.44, 1), -webkit-transform 0.4s cubic-bezier(0.165, 0.84, 0.44, 1); 
    }
}
.blog-landing {
    a {
        &.disabled {
            pointer-events: none;
            opacity: 0.4;
        }
    }
    &__featured-blog-list {
        .featured-blog-container {
            .main-blog {
                flex: 1 1 70%;
                &__image {
                    width: 100%;
                    object-fit: cover;
                }
                &__info {
                    position: relative;
                    padding: 24px 32px;
                    text-align: center;
                    max-width: 90%;
                    margin: -32px auto 0;
                    background-color: white;
                    @include breakpoint(md) {
                        margin: -64px auto 0;
                        max-width: 85%;
                        padding: 32px;
                    }
                }
                &__categories {
                    justify-content: center;
                    padding-bottom: 8px;
                }
                &__title {
                    font-weight: bold;
                    @include breakpoint(md) {
                        font-size: 40px;
                        line-height: 50px;
                    }
                }
            }
            .featured-blogs {
                flex: 1 1 30%;
                &__blog {
                    text-align: center;
                    padding: 16px;
                    @include breakpoint(md) {
                        padding: 32px 16px;
                    }
                    &:not(:last-of-type) {
                        border-bottom: 2px solid black;
                    }
                }
                &__blog-categories {
                    justify-content: center;
                    padding-bottom: 16px 0 8px;
                }
                &__blog-title {
                    font-weight: bold;
                }
            }
        }
    }
    &__blog-list {
        .blog-card {
            &__image {
                img {
                    @media (min-width: 768px) {
                        height: 205px;
                        width: 100%;
                        object-fit: cover;
                    }
                }
            }
            &__categories {
                justify-content: center;
                padding: 16px 0 8px;
            }
            &__title {

            }
        }
    }
    .pagination {
        &__results-qty {
            display: none;
        }
    }
    .category-link {
        background-color: black;
        color: $white;
        padding: 8px 12px;
        text-transform: uppercase;
        border-radius: 4px;
        margin-bottom: 8px;
        font-size: 12px;
        @include breakpoint(md) {
            font-size: 14px;
        }
        &:not(:last-of-type) {
            margin-right: 8px;
        }
    }
}
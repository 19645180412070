.blog-landing {
    .blog-card__categories .category-link,
    .category-link {
        background-color: transparent;
        color: $orange;
        text-decoration: underline;
        font-size: 14px;
        line-height: 20px;
        text-transform: uppercase;
        padding: 0 !important;
        &:hover {
            color: $pink !important;
        }
    }
    &__featured-blog-list {
        background-color: $cream;
        @include full_width_colour($cream);
        .main-blog__info {
            background-color: $cream !important;
        }
    }
}

.blog-category {
    background: $cream;
    .blog-category__category-banner {
        text-align: center;
        &-image {
            max-height: 500px;
            -o-object-fit: cover;
            object-fit: cover;
            width: 100%;
        }
        &-title {
            font-size: 40px;
            line-height: 49px;
            background-color: $yellow;
            display: inline-block;
            width: auto;
            padding: 10px 20px;
            position: relative;
            top: -35px;
        }
    }
    .blog-landing__blog-list-title {
        display: none;
    }
}

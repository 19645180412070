.center {
    text-align: center;
}

.left {
    text-align: left;
}

.right {
    text-align: right;
}

.defer-background {
    background-color: $background-defer;
    @include full_width_colour($background-defer);
}

.image-shadow {
    @extend %box-shadow;
}
.levels-content {
    .hero {
        &:not(:last-child) {
            padding-bottom: 30px;
            margin-bottom: 30px;
            border-bottom: 1px solid $yellow;
        }
        h1 {
            font-size: 20px;
            line-height: 26px;
        }
        h2 {
            margin-bottom: 64px;
            margin: 32px 0;
            margin-bottom: 16px;
            font-size: 18px;
            line-height: 30px;
            text-transform: none;
            @extend %secondary-font;
            @media (min-width: $m) {
                margin: 16px 0 64px;
            }
        }
        &__container {
            align-items: flex-start;
            &--left {
                order: 2;
                text-align: left;
                @media (min-width: $s) {
                    &.md\:w-2\/5 {
                        width: 75%;
                    }
                }
                .component__link a {
                    text-align: center;
                }
            }
            &--right {
                order: 1;
                @media (min-width: $s) {
                    &.md\:w-3\/5 {
                        width: 25%;
                    }
                }
                img {
                    margin: -50px auto 0 auto;
                    padding-top: 50px;
                    @media (min-width: $s) {
                        margin: -100px auto 0 auto;
                        padding-top: 100px;
                    }
                }
            }
        }
        .component__link {
            text-align: center;
            @media (min-width: $s) {
                text-align: left;
            }
            a {
                margin-top: 0;
            }
        }
    }
}

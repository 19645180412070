$normal-weight: 300;
$bold-weight: 700;
// default font sizes
$base-font-size: 16px;
$h1-font-size: 60px;
$h2-font-size: 35px;
$h3-font-size: 24px;
$h4-font-size: 20px;
$h5-font-size: 18px;
$h6-font-size: 15px;
// mobile font sizes
$h1-mobile-font-size: 35px;
$h2-mobile-font-size: 24px;
$h3-mobile-font-size: 22px;
%base-font {
    font-family: sans-serif;
    font-weight: $normal-weight;
}

%menu-font {
    font-family: sans-serif;
    font-weight: $normal-weight;
    text-transform: none;
}

%secondary-font {
    font-family: sans-serif;
    font-weight: $normal-weight;
}

blockquote {
    text-align: center;
}

cite {
    display: block;
}

a {
    transition: all $animation-time;
}
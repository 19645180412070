.book-landing {
    a {
        &.disabled {
            pointer-events: none;
            opacity: 0.4;
        }
    }
}

.book-card {
    .book-image {
        img {
            margin: 0 auto;
            @extend %box-shadow;
            transition: all .3s ease;
            &:hover {
                transform: scale(1.05);
            }
        }
    }
    ul{
        min-height: 100px;
        li {
            font-size: 14px;
            line-height: 16px;
            &.book-title {
                font-size: 18px;
                line-height: 20px;
                margin-bottom: 5px;
            }
            &.book-price {
                font-size: 16px;
                line-height: 18px;
                margin-bottom: 5px;
            }
        }
    }
    
}
.assets-filters {
    .assets-filters-list {
        .tns-outer {
            .tns-item {
                @include breakpoint("large-handset-landscape") {
                    max-width: 200px;
                }
                a {
                    height: 0  !important;
                    padding-bottom: 100% !important;
                    position: relative;
                    span {
                        position: absolute;
                        top: 50%;
                        left: 50%;
                        transform: translate(-50%, -50%);
                        pointer-events: none;
                    }
                }
            }
        }
    }
}
